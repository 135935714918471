import dynamic from 'next/dynamic';
import Loading from '@components/Loading';
import { HeroVariant } from '#constants';

import type { FC } from 'react';
import type { HeroProps as Props } from './types';

const findHeroComponent = (variant: string) => {
  switch (variant) {
    case HeroVariant.SIMPLE:
      return dynamic(() => import('./SimpleHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.EXTENDED:
      return dynamic(() => import('./ExtendedHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.VIDEO:
      return dynamic(() => import('./VideoHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.MEDIA:
      return dynamic(() => import('./MediaHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.HIGHLIGHT:
      return dynamic(() => import('./HighlightHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.SIDE_BY_SIDE:
      return dynamic(() => import('./SideBySideHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.ICON:
      return dynamic(() => import('./IconHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.ANIMATION:
      return dynamic(() => import('./AnimationHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.ERROR:
      return dynamic(() => import('./404Hero'), {
        loading: () => <Loading />
      });
    case HeroVariant.FEATURE:
      return dynamic(() => import('./FeatureHero'), {
        loading: () => <Loading />
      });
    case HeroVariant.STREAMING:
      return dynamic(() => import('./StreamingHero'), {
        loading: () => <Loading />
      });
    default:
      throw Error(`Variant type ${variant} is not a Hero variant.`);
  }
}

const Hero: FC<Props> = ({ variant, ...props }) => {
  const Component = findHeroComponent(variant);
  if (!Component) {
    throw `${variant} does not have a matching hero component.`;
  }
  return <Component {...props}/>;
};

export default Hero;
