import { FunctionComponent } from 'react';
import Head from './Head';
import BlackboxContent from '@components/BlackboxContent';
import Nav from '@components/Navigation';
import SiteBanner from '@components/SiteBanner';
import CouponBanner from '@components/CouponBanner';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import { useColorMode } from 'theme-ui';

import type { LayoutProps as Props } from './types';
import { H1 } from '#ui';

import ResponsiveMediaPreLoader from '@components/ResponsiveMedia/ResponsiveMediaPreLoader';

const DefaultLayout: FunctionComponent<Props> = ({
  bodyContent,
  title,
  seo,
  navigation,
  siteBanner,
  couponBanner,
  footer,
  hero,
  mode,
  slug,
  enableZendeskChatWidget,
  enableSiteBanner,
}) => {
  const [, setColorMode] = useColorMode();
  if (mode === 'Dark') {
    setColorMode('dark');
  }
  const showBanner = enableSiteBanner === null ? 'true' : enableSiteBanner;

  return (
    <>
      <Head
        pageTitle={title}
        seo={seo}
        noIndex={seo?.noIndex}
        enableZendeskChatWidget={enableZendeskChatWidget}
      />
      <ResponsiveMediaPreLoader media={hero?.responsiveMedia} />
      {showBanner && siteBanner && <SiteBanner {...siteBanner} />}
      {navigation && <Nav {...navigation} slug={slug} />}
      {couponBanner && <CouponBanner {...couponBanner} />}
      <>
        {hero && <Hero {...hero}></Hero>}
        {!hero && (
          <header className="global-sr">
            <H1>{title}</H1>
          </header>
        )}
        <main>
          {bodyContent?.map((item, i) => {
            return (
              <BlackboxContent key={item.sys.id} content={item} order={i} />
            );
          })}
        </main>
      </>
      {footer && <Footer {...footer} />}
      <div id="modal-root"></div>
    </>
  );
};
export default DefaultLayout;
