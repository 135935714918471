import { ColorHex } from '@lib/constants';
import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    INPAGE_ROOT: {
      display: 'flex',
      flexFlow: 'column nowrap',
      flexBasis: ['100%', '25%', '15%'],
      inlineSize: '100%',
      justifyContent: 'flex-start',
      listStyleType: 'none',
      marginBlockEnd: ['0px', 5],
      marginInlineStart: ['0px', 3],
      marginInlineEnd: ['0px', 3],
      paddingInlineStart: [1, '0px'],
      paddingInlineEnd: [1, '0px'],
    } as TSO,

    ACTIVE_LINK: {
      fontWeight: 'bold',
    } as TSO,

    INPAGENAV_BUTTON: {
      paddingInlineStart: 0,
      paddingInlineEnd: 0,
      marginBlockStart: [2, 5],
      marginBlockEnd: [2, 5],
      textAlign: 'center',
    } as TSO,

    TOP_TEXT: {} as TSO,

    INPAGE_CTA_LINKS: {
      marginBlockStart: 2,
    } as TSO,

    NAV_LINKS: {
      fontSize: '.875rem',
      lineHeight: '2.5',
      flexFlow: 'column nowrap',
      listStyleType: 'none',
      
      '& a:hover': {
        color: ColorHex.NeonBlue,
      }
    } as TSO,

    BOTTOM_COPY: {
      fontWeight: 'bold',
    } as TSO,

    BOTTOM_RESPONSIVE: {
      marginBlockStart: [0, 3],
      paddingBlockStart: 3,
      paddingBlockEnd: 3,
    } as TSO,
  };

  return Styles;
};
