import NextHead from 'next/head';
import ResponsiveMediaHelper from './ResponsiveMediaHelper';

import type { FC } from 'react';
import type { ResponsiveMediaPreLoaderProps as Props } from './types';

const ResponsiveMediaPreLoader: FC<Props> = ({ media }): JSX.Element | null => {
  if (!media) {
    return null;
  }

  const responsiveMediaHelper = new ResponsiveMediaHelper(
    media.desktopAsset,
    media.mobileAsset,
    media.wistiaVideoUrl,
    media.youtubeId
  );

  if (
    responsiveMediaHelper.isVideo ||
    responsiveMediaHelper.isWistiaVideo ||
    responsiveMediaHelper.isYouTubeVideo
  ) {
    return null;
  }

  if (media.desktopAsset?.url && responsiveMediaHelper.isStaticImage) {
    return (
      <NextHead>
        <link
          rel="preload"
          as="image"
          href={media.desktopAsset.url}
          imageSrcSet={responsiveMediaHelper.srcSet('desktop', 'webp')}
          media="(min-width:769px)"
        />
        {media.mobileAsset?.url && (
          <link
            rel="preload"
            as="image"
            href={media.mobileAsset.url}
            imageSrcSet={responsiveMediaHelper.srcSet('mobile', 'webp')}
            media="(max-width:768px)"
          />
        )}
      </NextHead>
    );
  }

  return null;
};

export default ResponsiveMediaPreLoader;
