import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    TWO_COLUMN_ROOT: {
      display: 'flex',
      flexFlow: ['column-reverse nowrap', 'row nowrap'],
      justifyContent: 'center',
      maxInlineSize: ['768px', '1440px', '1680px'],
    } as TSO,

    INPAGE_NAV: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    } as TSO,

    CONTENT_COLUMN: {
      display: 'flex',
      flexFlow: 'column nowrap',
      inlineSize: '100%',
      flexBasis: '60%',
      marginInlineStart: ['0px', 5, 7],
      marginInlineEnd: ['0px', 4, 6],
    } as TSO,
  };

  return Styles;
};
