import { useState } from 'react';
import { Block, Link, Text } from '#ui';
import AppButton from '@components/AppButton';
import { useStyles } from './inpageNavStyles';
import { getSpacing } from '@lib/utils';
import type { FunctionComponent } from 'react';
import type { InPageNavigationProps as Props } from './types';
import MarkdownText from '@components/MarkdownText';

const InPageNavigation: FunctionComponent<Props> = ({
  ctaButton,
  inPageLinksCollection,
  topCopy,
  topLink,
  bottomCopy,
  bottomLink,
  phoneNumber,
  order,
}) => {
  // Format Anchor Links
  const formatAnchor = (anchor: string): string => {
    const formattedAnchor = anchor.replace(/\s/g, '').toLowerCase();
    return formattedAnchor;
  };

  const [activeLink, setActiveLink] = useState(
    inPageLinksCollection?.items[0].destination || null
  );
  const Styles = useStyles();

  return (
    <aside
      sx={{
        marginBlockStart: getSpacing(order),
        ...Styles.INPAGE_ROOT,
      }}
    >
      <ul sx={Styles.NAV_LINKS}>
        {inPageLinksCollection?.items &&
          inPageLinksCollection.items.map((item) => (
            <li key={item.sys.id}>
              {item.destination ? (
                <a
                  href={formatAnchor(item.destination)}
                  sx={
                    activeLink === formatAnchor(item.destination)
                      ? Styles.ACTIVE_LINK
                      : undefined
                  }
                  onClick={() =>
                    setActiveLink(formatAnchor(item.destination || 'false'))
                  }
                >
                  {item.ctaCopy}
                </a>
              ) : (
                item.ctaCopy
              )}
            </li>
          ))}
      </ul>
      {ctaButton?.destination && (
        <AppButton {...ctaButton} componentStyle={Styles.INPAGENAV_BUTTON}>
          {ctaButton.ctaCopy || 'View More'}
        </AppButton>
      )}
      <Block sx={Styles.TOP_TEXT}>
        {topCopy && <MarkdownText>{topCopy}</MarkdownText>}
        {topLink?.destination && (
          <Link 
            withIcon 
            href={topLink.destination} 
            sx={Styles.INPAGE_CTA_LINKS}
            openInNewTab={topLink.openInNewTab}
          >
            {topLink.ctaCopy}
          </Link>
        )}
      </Block>

      <Block as="div" sx={Styles.BOTTOM_RESPONSIVE}>
        {bottomCopy && <p sx={Styles.BOTTOM_COPY}>{bottomCopy}</p>}
        {bottomLink?.destination && (
          <Link 
            withIcon 
            href={bottomLink.destination} 
            sx={Styles.INPAGE_CTA_LINKS}
            openInNewTab={bottomLink.openInNewTab}
          >
            {bottomLink.ctaCopy}
          </Link>
        )}
        {phoneNumber && <Text>{phoneNumber}</Text>}
      </Block>
    </aside>
  );
};

export default InPageNavigation;
