import DefaultLayout from './DefaultLayout';
import TwoColumnLayout from './TwoColumn';
import { PageVariant } from '@lib/constants/page';

import type { FC } from 'react';
import type { LayoutProps as Props } from './types';

const Layout: FC<Props> = (props) => {
  switch (props.variant) {
    case PageVariant.DEFAULT:
      return <DefaultLayout {...props} />;
    case PageVariant.TWO_COLUMN:
      return <TwoColumnLayout {...props} />;
    default:
      throw Error(`Variant type ${props.variant} is not a Page variant.`);
  }
};

export default Layout;
