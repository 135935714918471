import { FunctionComponent } from 'react';
import Head from './Head';
import BlackboxContent from '@components/BlackboxContent';
import Nav from '@components/Navigation';
import SiteBanner from '@components/SiteBanner';
import CouponBanner from '@components/CouponBanner';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import { useColorMode } from 'theme-ui';
import { useStyles } from './layouts.styles';
import { ContentType } from '#constants';
import { Block, Grid, H1 } from '#ui';

import type { LayoutProps as Props } from './types';
import InPageNavigation from '@components/InPageNavigation';

const TwoColumnLayout: FunctionComponent<Props> = ({
  bodyContent,
  seo,
  navigation,
  siteBanner,
  couponBanner,
  footer,
  hero,
  mode,
  slug,
  title,
  enableZendeskChatWidget,
  enableSiteBanner,
}) => {
  const showBanner = enableSiteBanner === null ? 'true' : enableSiteBanner;

  const inPageNav = bodyContent?.filter(
    (item) => item.__typename === ContentType.INPAGE_NAV
  )[0];

  const filteredContent = bodyContent?.filter(
    (item) => item.__typename !== ContentType.INPAGE_NAV
  );

  const Styles = useStyles();

  const [, setColorMode] = useColorMode();

  if (mode === 'Dark') {
    setColorMode('dark');
  }

  return (
    <>
      <Head
        pageTitle={title}
        seo={seo}
        noIndex={seo?.noIndex}
        enableZendeskChatWidget={enableZendeskChatWidget}
      />
      {showBanner && siteBanner && <SiteBanner {...siteBanner} />}
      {navigation && <Nav {...navigation} slug={slug} />}
      {couponBanner && <CouponBanner {...couponBanner} />}
      <>
        {hero && <Hero {...hero}></Hero>}
        {!hero && (
          <header className="global-sr">
            <H1>{title}</H1>
          </header>
        )}
        <Grid as="main" sx={Styles.TWO_COLUMN_ROOT} wrap noPadding>
          {inPageNav && <InPageNavigation order={1} {...inPageNav} />}
          <Block sx={Styles.CONTENT_COLUMN}>
            {filteredContent?.map((item, i) => {
              return (
                <BlackboxContent key={item.sys.id} content={item} order={i} />
              );
            })}
          </Block>
        </Grid>
      </>
      {footer && <Footer {...footer} />}
      <div id="modal-root"></div>
    </>
  );
};
export default TwoColumnLayout;
